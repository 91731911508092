<template>
  <div v-if="scope.row.scale.type">
    <click-to-edit
      v-model="scope.row.scale.target_achieve_level"
      placeholder=""
      :disabled="scope.row.scale.type !== scaleTypesEnum.TYPE_QUALITY || !isFieldAvailableForEdit(scope.row, 'scale.target_achieve_level')"
      type="select"
      select-filterable
      :select-options="[
        {id: 1, name:'Не выполнено'},
        {id: 2, name:'Частично выполнено'},
        {id: 3, name:'Выполнено'},
        {id: 4, name:'Перевыполнено'},
      ]"
      select-option-id="id"
      select-option-name="name"
      select-cast-value-to-int
      @input="saveElementPropChange(scope.row, 'scale', {target_achieve_level:$event})"
    >
    </click-to-edit>
  </div>

  <div
    v-else
    style="color: #b1b1b2;"
  >
    Сначала выберите тип цели
  </div>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
import {variables} from "@/componentsCompany/smz/variables";
export default {
  name: 'element-period-result-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  computed: {
    showFillFactPlaceholder(){
      if( this.scope.row.scale.type !== this.scaleTypesEnum.TYPE_QUALITY ){
        return false;
      }
      return !!this.scope.row.scale.fact;
    }
  },
  data() {
    return {
      scaleTypesEnum: variables.scaleTypesEnum,
    }
  },
  methods: {

  }
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";

</style>
